import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
    imports: [CommonModule],
    selector: 'dialog-end-trial',
    template: `
    <h1> End of Trial, thank you </h1>
    `
})

export class EndTrialDialog {
}