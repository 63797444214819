import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { ActionEvent, toggleElementOnClick } from '@app/frontend-core';
import { PeopleGear, SubscriptionGear } from '@app/frontend-state';
import { LayoutGrid, LogOut, LucideAngularModule, Package, Store } from "lucide-angular"
import { ChangeThemeComponent } from 'frontend-ui/src/components/change-theme.component';
import { DialogRef } from '@ngneat/dialog';
import { DialogsPeople } from '../people';

@Component({
    selector: 'user-dialog',
    imports: [
        CommonModule,
        LucideAngularModule,
        ChangeThemeComponent
    ],
    template: `

  @if(peopleGear.myProfile(); as user) {
    <div class="h-full p-4 md:p-8">
      
      @if(subscriptionGear.mySubscription(); as sub) {
      <div class="flex flex-col justify-around align-center full">
        <div class="flex flex-col items-center pb-3 md:pb-6 h-full">
            <!-- <div class="px-4">
              <span class="scale-75 text-xs w-9 h-9 radial-progress text-success"
              [style]="'--size:12rem; --thickness: 2px;' + '--value:' + (sub?.subscriptionBalance / sub?.subscriptionDailyValue) * 100" role="progressbar">
              {{ (sub?.subscriptionBalance / sub?.subscriptionDailyValue) * 100 | number:'1.0-0' }}%
              </span>
            </div> -->
            <div class="flex w-full flex-col items-center">
              
              <span class="font-bold">Gói đăng ký </span>
            
                <div class="overflow-x-auto pt-2">
                  <table class="table">
                    <!-- head -->
                    <thead>
                      <tr>
                        <th class="text-xs md:text-base whitespace-normal">Gói</th>
                        <th class="text-xs md:text-base whitespace-normal">Giá trị</th>
                        <th class="text-xs md:text-base whitespace-normal">Tiền trong ví</th>
                        <th class="text-xs md:text-base whitespace-normal">Hành động</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- row 1 -->
                      <tr>
                        <th>{{ sub?.subscriptionType === 'paid' ? 'Trả phí' : 'Miễn phí' }}</th>
                        <td>{{ sub?.subscriptionMonthlyValue | currency:'VND':'symbol':'1.0-0' }}</td>
                        <td>{{ sub?.walletBalance | currency:'VND':'symbol':'1.0-0' }}</td>
                        <td>
                          @switch (sub?.walletAction) {
                            @case ('SUBSCRIPTION_NEED_DEPOSIT') {
                              Tháng tiếp theo hết tiền, cần gia hạn
                            }
                            @case ('SUBSCRIPTION_CAN_RENEW') {
                              Tự động gia hạn
                            }
                            @case ('SUBSCRIPTION_CAN_RESET') {
                              Không cần gia hạn
                            }
                            @default {
                              <!-- Default case, if needed -->
                            }
                          }
                        </td>
                        <!-- <td>{{ sub?.subscriptionEndCycleDate | date: 'short' }}</td> -->
                      </tr>
                  </table>
                </div>

                <div class="overflow-x-auto pt-2">
                  <table class="table">
                    <!-- head -->
                    <thead>
                      <tr>
                        <th class="text-xs md:text-base whitespace-normal">Tiêu thụ hôm nay</th>
                        <th class="text-xs md:text-base whitespace-normal">Giới hạn ngày</th>
                        <th class="text-xs md:text-base whitespace-normal">Ngày gia hạn tiếp theo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- row 1 -->
                      <tr>
                        <td>{{ sub?.subscriptionBalance | currency:'VND':'symbol':'1.0-0' }}</td>
                        <td>{{ sub?.subscriptionDailyValue | currency:'VND':'symbol':'1.0-0' }}</td>
                        <td>{{ sub?.subscriptionEndCycleDate | date: 'short' }}</td>
                      </tr>
                  </table>
                </div>

                <!-- <div class="flex w-full items-center justify-around pt-2">
                  <div class="flex flex-col">
                      <span class="text-xs pt-2"> Gói tháng </span>
                      <span class="text-xs"> {{ sub?.subscriptionMonthlyValue | currency:'VND':'symbol':'1.0-0' }} </span>
                  </div>
                  <div class="flex flex-col">
                    <span class="text-xs pt-2">Giới hạn ngày </span>
                    <span class="text-xs"> {{ sub?.subscriptionBalance | currency:'VND':'symbol':'1.0-0' }} / {{ sub?.subscriptionDailyValue | currency:'VND':'symbol':'1.0-0' }} </span>
                  </div>
                </div> -->
            
            </div>
        </div>
      </div>
      } @else {
        <p> Lỗi: không có thông tin đăng ký, vui lòng liên hệ fanpage: <a href="https://www.facebook.com/losaai/" target="_blank"></a> </p>
      }

      <div class="flex flex-col items-center pt-2 pb-3 md:pb-6 h-full">
        <span class="font-bold">Hồ sơ</span>
        <span class="text-xs py-1">username: {{ peopleGear.myProfile()?.username }}</span>
        <app-change-theme></app-change-theme>
      </div>

      <!-- <pre class="p-2"> subscriptionGear.mySubscription() {{ subscriptionGear.mySubscription() | json }} </pre> -->
    
    <ul tabindex="0" class="menu menu-compact p-2 shadow bg-base-100 rounded-box w-full">
      <li (click)="action({type: 'open-subscription'})">
        <span class="py-2 flex items-center text-sm text-base-content cursor-pointer">
          <lucide-icon [name]="icons.Package"></lucide-icon>
          Quản lý đăng ký
        </span>
      </li>
      <li (click)="action({type: 'go-upgrade'})">
        <span class="py-2 flex items-center text-sm text-base-content cursor-pointer">
          <lucide-icon [name]="icons.Store"></lucide-icon>
          Nâng cấp
        </span>
      </li>
      <li (click)="action({type: 'message'})">
        <span class="py-2 flex items-center text-sm text-base-content cursor-pointer">
          <lucide-icon [name]="icons.Store"></lucide-icon>
          Nhắn tin hỗ trợ 
        </span>
      </li>
      <li (click)="action({type: 'logout'})">
        <span class="py-2 flex items-center text-sm text-base-content cursor-pointer">
          <lucide-icon [name]="icons.LogOut"></lucide-icon>
          Đăng xuất
        </span>
      </li>
    </ul>
  </div>
  } @else {
    <p class="p-10"> Vui lòng đăng nhập </p>
    <div class="flex p-4 justify-center">
      <button class="btn btn-outline mx-auto" (click)="dialogPeople.openDialogLogin()"> Đăng nhập </button>
    </div>
  }

  `,
    styles: [
        `
      :host {
        display: block;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDialog {
  icons = { LogOut, Package, Store }
  toggleElementOnClick = toggleElementOnClick;
  peopleGear = inject(PeopleGear);
  dialogPeople = inject(DialogsPeople);
  subscriptionGear = inject(SubscriptionGear);
  dialogRef = inject(DialogRef);
  router = inject(Router);

  constructor() {
    this.subscriptionGear.mySubscriptionQuery.refetch();
  }

  action(event: ActionEvent) {
    switch (event.type) {
      case 'open-subscription':
        this.subscriptionGear.openSubscriptionDialog();
        break;
      case 'go-upgrade':
        this.router.navigate(['/pricing'])
        break;
      case 'message':
        window.open('https://www.messenger.com/t/385489534653915', '_blank', 'noopener,noreferrer');
        break;

      case 'logout':
        this.peopleGear.logoutMutation.mutate(undefined, {
          onSuccess: () => {
            location.reload();
          },
        });
        break;
    }

    this.dialogRef.close();
  }
}
