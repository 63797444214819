export interface AICharacterInformation {
    characterId: string;
    characterUsername: string;
    characterDisplayName: string;
    characterDescription: string;
    userSuggestions: string[];
    characterImage: string | null;
    characterOpening: string | null;
    characterInstruction?: string;
    categoryId: string;
    categoryName:string;
    categoryFile: string;
    }
    
export const informationOfCharacters:AICharacterInformation[] = [
  {
    "characterId": "losa",
    "characterUsername": "losa",
    "characterDisplayName": "Losa",
    "characterDescription": "vâng, tôi là Losa! Thư ký AI trực tuyến đáng yêu của bạn đây <3. Lovely Online Secretary AI",
    "userSuggestions": [
      "tại sao biển lại mặn?",
      "Giải thích cách thức hoạt động của thuật toán tìm kiếm nhị phân",
      " So sánh sự tương phản giữa sinh sản vô tính và sinh sản hữu tính"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "common",
    "categoryFile": "common_characters.category.yaml",
    "categoryName": "Chung"
  },
  {
    "characterId": "luna",
    "characterUsername": "luna",
    "characterDisplayName": "Luna",
    "characterDescription": "Xin chào! Em là Luna - AI Assistant thân thiện đến từ Losa. Em được tạo ra để đồng hành và hỗ trợ người dùng Việt Nam trong mọi nhiệm vụ. Với khả năng giao tiếp tự nhiên bằng tiếng Việt và hiểu văn hóa Việt, em mong muốn trở thành người bạn đáng tin cậy của bạn <3",
    "userSuggestions": [
      "Giải thích khái niệm trí tuệ nhân tạo cho trẻ em",
      "Lập kế hoạch kinh doanh cho một startup mới",
      "Hướng dẫn học tiếng Anh hiệu quả",
      "Cách viết một bài văn thuyết phục hay"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "common",
    "categoryFile": "common_characters.category.yaml",
    "categoryName": "Chung"
  },
  {
    "characterId": "losa-gentle",
    "characterUsername": "losa-gentle",
    "characterDisplayName": "Losa dịu dàng",
    "characterDescription": "Losa tư vấn tâm lý, tình cảm",
    "userSuggestions": [
      "Tôi nên làm gì khi bạn gái quá trẻ con?",
      "Cần làm gì khi tôi và người yêu không có chung quan điểm?",
      " Đã 30 tuổi rồi mà tôi vẫn chưa có người yêu, tôi phải làm gì bây giờ?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "common",
    "categoryFile": "common_characters.category.yaml",
    "categoryName": "Chung"
  },
  {
    "characterId": "losa-funny",
    "characterUsername": "losa-funny",
    "characterDisplayName": "Losa hài hước",
    "characterDescription": "Losa giúp bạn thư giãn và vui vẻ",
    "userSuggestions": [
      "Bạn tên gì?",
      "Bạn làm người yêu tôi được không?",
      " Bạn có biết kể chuyện cười không?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "common",
    "categoryFile": "common_characters.category.yaml",
    "categoryName": "Chung"
  },
  {
    "characterId": "losa-upmood",
    "characterUsername": "losa-upmood",
    "characterDisplayName": "Losa sâu sắc",
    "characterDescription": "Losa giúp bạn có thêm động lực",
    "userSuggestions": [
      "Hôm nay tôi cảm thấy chán quá, làm gì cũng hỏng, bạn có thể an ủi tôi một chút không?",
      "Tôi không muốn đi làm, muốn đi chữa lành, nhưng không có tiền",
      " Cả thế giới ai cũng vui vẻ trừ tôi ra, tôi nên làm gì bây giờ?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "common",
    "categoryFile": "common_characters.category.yaml",
    "categoryName": "Chung"
  },
  {
    "characterId": "losa-career",
    "characterUsername": "losa-career",
    "characterDisplayName": "Losa Tư vấn nghề nghiệp",
    "characterDescription": "Losa giúp bạn tìm hiểu về nghề nghiệp và tư vấn lựa chọn nghề nghiệp",
    "userSuggestions": [
      "Tôi muốn làm freelancer thì nên bắt đầu từ đâu?",
      "Nếu muốn kinh doanh bất động sản thì cần phải làm gì?",
      "Liệu tôi có thể trở thành ca sĩ không?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "business",
    "categoryFile": "business_characters.category.yaml",
    "categoryName": "Công việc"
  },
  {
    "characterId": "losa-university-selector",
    "characterUsername": "losa-university-selector",
    "characterDisplayName": "Losa Tư vấn chọn trường Đại học",
    "characterDescription": "Losa giúp bạn tìm hiểu về ngành học, trường đại học và hỗ trợ lựa chọn trường Đại học",
    "userSuggestions": [
      "Điểm chuẩn ngành Kinh tế của trường Đại học Quốc gia Hà Nội năm 2023 là bao nhiêu?",
      "Ngành Y tế có triển vọng nghề nghiệp ra sao trong tương lai?",
      "Ngành sư phạm nên học ở trường nào nếu thi được 20 điểm?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "business",
    "categoryFile": "business_characters.category.yaml",
    "categoryName": "Công việc"
  },
  {
    "characterId": "losa-marketing",
    "characterUsername": "losa-marketing",
    "characterDisplayName": "Losa marketing",
    "characterDescription": "Losa hỗ trợ bạn công việc marketing",
    "userSuggestions": [
      "Hãy giúp tôi phân tích thị trường cho sản phẩm mới của tôi",
      "Hãy cho tôi biết cách đo lường hiệu quả của chiến dịch marketing",
      "Hãy phân tích SWOT về sản phẩm của tôi"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "business",
    "categoryFile": "business_characters.category.yaml",
    "categoryName": "Công việc"
  },
  {
    "characterId": "losa-seo",
    "characterUsername": "losa-seo",
    "characterDisplayName": "Losa SEO",
    "characterDescription": "Losa hỗ trợ bạn công việc SEO",
    "userSuggestions": [
      "Hãy giúp  nghiên cứu từ khoá và đề xuất danh sách từ khoá cho website của công ty tôi.",
      "Hãy giúp tôi lên kế hoạch SEO cho sản phẩm của tôi",
      "Hãy cho tôi biết cách phân tích tình trạng website hiệu quả"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "business",
    "categoryFile": "business_characters.category.yaml",
    "categoryName": "Công việc"
  },
  {
    "characterId": "losa-content",
    "characterUsername": "losa-content",
    "characterDisplayName": "Losa content",
    "characterDescription": "Losa hỗ trợ bạn viết content",
    "userSuggestions": [
      "Hãy giải thích cho tôi về các công thức viết nội dung",
      "Hãy viết giúp tôi một bài đăng facebook giới thiệu về dịch vụ niềng răng",
      "Làm thế nào để đảm bảo nội dung luôn độc đáo và không đạo văn?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "business",
    "categoryFile": "business_characters.category.yaml",
    "categoryName": "Công việc"
  },
  {
    "characterId": "losa-programing",
    "characterUsername": "losa-programing",
    "characterDisplayName": "Losa lập trình",
    "characterDescription": "Losa giúp trả lời câu hỏi lập trình",
    "userSuggestions": [
      "write html login form in angular",
      "why sometime height 100% not get full screen?",
      "increase node max size memory command for nodejs",
      "different between access_token and refresh_token?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "business",
    "categoryFile": "business_characters.category.yaml",
    "categoryName": "Công việc"
  },
  {
    "characterId": "losa-document-editor",
    "characterUsername": "losa-document-editor",
    "characterDisplayName": "Losa Biên tập",
    "characterDescription": "Losa hỗ trợ bạn viết content",
    "userSuggestions": [
      "Hãy giúp tôi chỉnh sửa chính tả cho đoạn văn của tôi",
      "Hãy đánh giá và cho tôi góp ý cải thiện cho đoạn văn này",
      "Câu này đang sử dụng biện pháp nghệ thuật nào?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "business",
    "categoryFile": "business_characters.category.yaml",
    "categoryName": "Công việc"
  },
  {
    "characterId": "losa-communicator",
    "characterUsername": "losa-communicator",
    "characterDisplayName": "Losa Giao tiếp",
    "characterDescription": "Losa hỗ trợ bạn phát triển kỹ năng giao tiếp",
    "userSuggestions": [
      "Hãy viết cho tôi một email xin việc chuyên nghiệp?",
      "Hãy hướng dẫn tôi cách thuyết trình hiệu quả?",
      "Tôi nên giao tiếp thế nào khi đi phỏng vấn xin việc?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "business",
    "categoryFile": "business_characters.category.yaml",
    "categoryName": "Công việc"
  },
  {
    "characterId": "losa-legal",
    "characterUsername": "losa-legal",
    "characterDisplayName": "Losa Hỏi đáp luật",
    "characterDescription": "Losa trả lời câu hỏi pháp lý để bạn tham khảo",
    "userSuggestions": [
      "Hãy cho tôi biết quy trình thành lập doanh nghiệp tại Việt Nam?",
      "Người lao động nữ sinh con được hưởng chế độ thai sản như thế nào?",
      "Đi xe máy không đội mũ bảo hiểm bị phạt bao nhiêu tiền?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "business",
    "categoryFile": "business_characters.category.yaml",
    "categoryName": "Công việc"
  },
  {
    "characterId": "losa-travel",
    "characterUsername": "losa-travel",
    "characterDisplayName": "Losa Du lịch",
    "characterDescription": "Losa giúp Lên lịch trình và gợi ý văn hoá, ẩm thực... về các điểm du lịch tại Việt Nam phù hợp với bạn",
    "userSuggestions": [
      "Hãy thiết kế gíup tôi lịch trình đi chơi Mộc Châu 3 ngày 2 đêm, di chuyển từ Hà Nội",
      "Đi du lịch Huế nên ăn món gì?",
      "Hãy giới thiệu cho tôi về văn hoá của đồng bào Khmer"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "life",
    "categoryFile": "life_characters.category.yaml",
    "categoryName": "Cuộc sống"
  },
  {
    "characterId": "losa-fashion",
    "characterUsername": "losa-fashion",
    "characterDisplayName": "Losa Thời trang",
    "characterDescription": "Losa giúp bạn phối đồ, gợi ý mặc đẹp",
    "userSuggestions": [
      "Hãy gợi ý cho tôi một số hãng thời trang phù hợp với sinh viên",
      "Hãy chia sẻ cho tôi một số mẹo phụ kiện phù hợp với trang phục công sở",
      "Lần đầu ra mắt bố mẹ người yêu nên mặc gì?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "life",
    "categoryFile": "life_characters.category.yaml",
    "categoryName": "Cuộc sống"
  },
  {
    "characterId": "losa-makeup",
    "characterUsername": "losa-makeup",
    "characterDisplayName": "Losa Làm đẹp",
    "characterDescription": "Losa giúp bạn chăm sóc sắc đẹp",
    "userSuggestions": [
      "Hãy tư vấn cho tôi quy trình chăm sóc da nhờn",
      "Tôi nên trang điểm như thế nào để phù hợp với tiệc cưới bạn thân?",
      "Tôi cần làm gì để giữ được vóc dáng đẹp?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "life",
    "categoryFile": "life_characters.category.yaml",
    "categoryName": "Cuộc sống"
  },
  {
    "characterId": "losa-cooking",
    "characterUsername": "losa-cooking",
    "characterDisplayName": "Losa Nấu cơm",
    "characterDescription": "Losa giúp bạn nấu cơm vui hơn",
    "userSuggestions": [
      "Cách nấu món đậu phụ sốt cà chua",
      "Có nên cho gừng vào thịt bò xào hành tây không?",
      "Cách nấu món thịt gà nướng mật ong"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "life",
    "categoryFile": "life_characters.category.yaml",
    "categoryName": "Cuộc sống"
  },
  {
    "characterId": "losa-health",
    "characterUsername": "losa-health",
    "characterDisplayName": "Losa Tư vấn sức khỏe",
    "characterDescription": "Losa giúp bạn tham khảo để chăm sóc sức khỏe tốt hơn",
    "userSuggestions": [
      "Tôi nên làm gì khi bị mẩn ngứa?",
      "Bị lên sởi có sốt không?",
      "Biểu hiện của bệnh đậu mùa là gì?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "health",
    "categoryFile": "health_characters.category.yaml",
    "categoryName": "Y tế"
  },
  {
    "characterId": "losa-nutrition",
    "characterUsername": "losa-nutrition",
    "characterDisplayName": "Losa Tư vấn dinh dưỡng",
    "characterDescription": "Losa giúp bạn tham khảo chế độ dinh dưỡng hợp lý",
    "userSuggestions": [
      "Phụ nữ mang thai tuần 8 nên ăn gì để bổ đảm bảo dinh dưỡng?",
      "Hãy thiết kế cho tôi một thực đơn giảm cân an toàn và lành mạnh",
      "Người bị sỏi thận không nên ăn gì?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "health",
    "categoryFile": "health_characters.category.yaml",
    "categoryName": "Y tế"
  },
  {
    "characterId": "losa-science",
    "characterUsername": "losa-science",
    "characterDisplayName": "Losa Khoa học vui",
    "characterDescription": "Losa giúp giải thích các khái niệm khoa học phức tạp một cách dễ hiểu, và chia sẻ những khám phá mới nhất trong các lĩnh vực khoa học khác nhau.",
    "userSuggestions": [
      "Hãy giải thích cho tôi hiện tượng \"Ánh sáng cầu vồng\"",
      "Làm thế nào để kết nối vật lý với cuộc sống hàng ngày của chúng ta?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "educate",
    "categoryFile": "educate_characters.category.yaml",
    "categoryName": "Giáo dục"
  },
  {
    "characterId": "losa-literary",
    "characterUsername": "losa-literary",
    "characterDisplayName": "Losa Văn học",
    "characterDescription": "Losa giải đáp thắc mắc về bộ môn ngữ văn, giúp bạn học giỏi văn hơn.",
    "userSuggestions": [
      "Hãy giải thích giúp tôi khái niệm \"thể loại văn học\" trong Ngữ văn Việt Nam",
      "Hãy phân tích giúp tôi bài thơ \"Tây Tiến\"",
      "Cho tôi biết một số mẹo để viết bài văn nghị luận xã hội hiệu quả hơn"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "educate",
    "categoryFile": "educate_characters.category.yaml",
    "categoryName": "Giáo dục"
  },
  {
    "characterId": "losa-language",
    "characterUsername": "losa-language",
    "characterDisplayName": "Losa Ngoại ngữ",
    "characterDescription": "Losa giải đáp thắc mắc và cùng học ngoại ngữ.",
    "userSuggestions": [
      "Hãy giúp tôi tạo ra một bài tập ngắn môn Tiếng Anh về thì hiện tại đơn",
      "Làm thế nào để vượt qua nỗi sợ khi giao tiếp",
      "Hãy gợi ý cho tôi một trò chơi ngôn ngữ thú vị"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "educate",
    "categoryFile": "educate_characters.category.yaml",
    "categoryName": "Giáo dục"
  },
  {
    "characterId": "losa-history",
    "characterUsername": "losa-history",
    "characterDisplayName": "Losa Lịch sử",
    "characterDescription": "Losa giúp việc tìm hiểu lịch sử trở nên đơn giản hơn.",
    "userSuggestions": [
      "Hãy cho tôi biết về trận chiến sông Bạch Đằng diễn ra năm bao nhiêu?",
      "Tôi nên làm gì để học giỏi Lịch sử Việt Nam?",
      "Con sông đã đi vào lịch sử dân tộc Việt Nam trong Chiến dịch Việt Bắc năm 1947 tên là gì?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "educate",
    "categoryFile": "educate_characters.category.yaml",
    "categoryName": "Giáo dục"
  },
  {
    "characterId": "losa-boyfriend",
    "characterUsername": "losa-boyfriend",
    "characterDisplayName": "Losa Tổng tài Ngôn tình",
    "characterDescription": "Người bạn trai ngôn tình tổng tài ấm áp của bạn",
    "userSuggestions": [
      "Em ghét thế giới này",
      "Em nhớ anh",
      "Anh đang làm gì thế?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "entertainment",
    "categoryFile": "entertainment_characters.category.yaml",
    "categoryName": "Giải trí"
  },
  {
    "characterId": "losa-girlfriend",
    "characterUsername": "losa-girlfriend",
    "characterDisplayName": "Losa Cô bạn gái đáng yêu",
    "characterDescription": "Cô bạn gái đáng yêu dễ thương của bạn",
    "userSuggestions": [
      "Hôm nay của anh tệ quá?",
      "Anh muốn gặp em ",
      "Nhớ anh không?"
    ],
    "characterImage": null,
    "characterOpening": null,
    "categoryId": "entertainment",
    "categoryFile": "entertainment_characters.category.yaml",
    "categoryName": "Giải trí"
  }
];
export interface CharacterCategory {
    categoryId: string;
    categoryName: string;
    }

    
export const characterCategories: CharacterCategory[] = [
  {
    "categoryId": "common",
    "categoryName": "Chung"
  },
  {
    "categoryId": "business",
    "categoryName": "Công việc"
  },
  {
    "categoryId": "life",
    "categoryName": "Cuộc sống"
  },
  {
    "categoryId": "health",
    "categoryName": "Y tế"
  },
  {
    "categoryId": "educate",
    "categoryName": "Giáo dục"
  },
  {
    "categoryId": "entertainment",
    "categoryName": "Giải trí"
  }
];