import { Injectable, signal } from '@angular/core';
import { AppStorageObject } from '@app/generated';
import { TreeNode } from 'configs/global';
import { nanoid } from 'nanoid';

export enum TreeNodeAction {
    ADD = 'add',
    DELETE = 'delete',
    UPDATE = 'update',
}

@Injectable({
    providedIn: 'root'
})
export class NodeManagerService {
    readonly ROOT_ID = "";
    logs = signal<({ node: TreeNode, action: TreeNodeAction, timestamp: number })[]>([]);
    nodeMap = signal<Map<string, TreeNode>>(new Map());
    childrenMap = signal<Map<string | null, string[]>>(new Map());
    rootNode = signal<TreeNode>(this.createRootNode('temp-id', 'temp-key'));
    activeNode = signal<TreeNode>(this.rootNode());

    clearLogs() {
        this.logs.set([]);
    }

    selectRootNode(): TreeNode {
        return this.nodeMap().get(this.ROOT_ID)!;
    }

    private createRootNode(projectId: string, rootKey: string): TreeNode {
        return {
            parentId: '',
            objectId: '',
            projectId: projectId,
            name: 'THIS IS ROOT',
            path: `/`,
            position: 0,
        };
    }

    addNode(node: TreeNode) {
        this.nodeMap.update(map => {
            const newMap = new Map(map);
            newMap.set(node.objectId, node);
            return newMap;
        });

        this.childrenMap.update(map => {
            const newMap = new Map(map);
            const actualParentId = node.parentId;
            if (!newMap.has(actualParentId)) {
                newMap.set(actualParentId, []);
            }
            newMap.get(actualParentId)!.push(node.objectId);
            return newMap;
        });

        this.recalculateNodePosition({ nodeId: node.objectId, newIndex: node.position });
    }

    updateNode(objectId: string, updates: Partial<TreeNode>) {
        this.updateNodeInMap(objectId, updates);
    }

    deleteNode(nodeToRemove: TreeNode) {
        const removingObjectId = nodeToRemove.objectId;
        if (removingObjectId === this.ROOT_ID) {
            console.warn("Cannot remove root node");
            return;
        }

        const children = this.childrenMap().get(removingObjectId);
        if (children) {
            children.forEach(childId => {
                const childNode = this.nodeMap().get(childId);
                if (childNode) {
                    this.deleteNode(childNode);
                }
            });
        }

        this.logs.update(log => {
            const affectedNodes = this.getAffectedNodes(removingObjectId);
            return [
                ...log,
                ...affectedNodes.map(affectedNode => ({ node: affectedNode, action: TreeNodeAction.DELETE, timestamp: Date.now() })),
            ];
        });

        this.nodeMap.update(currentMap => {
            const newMap = new Map(currentMap);
            newMap.delete(removingObjectId);
            return newMap;
        });

        this.childrenMap.update(currentMap => {
            const newMap = new Map(currentMap);
            const parentId = nodeToRemove.parentId || this.ROOT_ID;
            const parentChildren = newMap.get(parentId);
            if (parentChildren) {
                const index = parentChildren.indexOf(removingObjectId);
                if (index !== -1) {
                    parentChildren.splice(index, 1);
                    if (parentChildren.length === 0) {
                        newMap.delete(parentId);
                    } else {
                        newMap.set(parentId, parentChildren);
                    }
                }
            }
            return newMap;
        });

        // Instead of recalculateNodePositionByParentId, use updateChildPositions
        const parentId = nodeToRemove.parentId || this.ROOT_ID;
        const updatedChildIds = (this.childrenMap().get(parentId) || []).filter(id => id !== removingObjectId);
        this.updateChildPositions(parentId, updatedChildIds);
    }

    // Update updateNodeAndDescendants to handle nested paths
    private updateNodeAndDescendants(node: TreeNode, newParentId: string, newPath: string, updateChildrenMap: boolean = false): void {
        const pathParts = newPath.split('/').filter(Boolean);
        const derivedParentId = pathParts.length === 1
            ? this.ROOT_ID
            : pathParts[pathParts.length - 2];

        const updatedNode: TreeNode = {
            ...node,
            parentId: derivedParentId,
            path: newPath,
        };

        this.updateNode(node.objectId, updatedNode);

        const childNodes = this.getChildNodes(node.objectId);
        childNodes.forEach(childNode => {
            const childNewPath = `${newPath}/${childNode.objectId}`;
            this.updateNodeAndDescendants(childNode, node.objectId, childNewPath);
        });

        if (updateChildrenMap) {
            this.childrenMap.set(this.updateChildrenMap(this.nodeMap()));
        }
    }

    private getAffectedNodes(nodeId: string): TreeNode[] {
        const node = this.nodeMap().get(nodeId);
        if (!node) return [];

        const affectedNodes: TreeNode[] = [node];
        const childNodes = this.getChildNodes(nodeId);
        affectedNodes.push(...childNodes);

        return affectedNodes;
    }

    relocateNode(node: TreeNode, targetNode: TreeNode, position: 'before' | 'inside' | 'remove') {
        switch (position) {
            case 'remove':
                this.deleteNode(node);
                break;
            default:
                const parentNode: TreeNode = position === 'inside' ? targetNode : this.nodeMap().get(targetNode.parentId)!;
                if (!parentNode) {
                    console.error(`Parent node not found for relocation`);
                    return;
                }

                // Remove node from old parent's children first
                const oldParentId = node.parentId || this.ROOT_ID;
                this.childrenMap.update(map => {
                    const newMap = new Map(map);
                    const oldParentChildren = newMap.get(oldParentId) || [];
                    newMap.set(oldParentId, oldParentChildren.filter(id => id !== node.objectId));
                    return newMap;
                });

                // Calculate new path and update node hierarchy
                const newPath = this.calculateNewPath(parentNode, node);
                this.updateNodeAndDescendants(node, parentNode.objectId, newPath, true);

                // Calculate and apply new position
                const newIndex = this.calculateNewIndex(parentNode, targetNode, position);
                this.recalculateNodePosition({ nodeId: node.objectId, newIndex });

                break;
        }
    }

    private calculateNewPath(parentNode: TreeNode, node: TreeNode): string {
        return parentNode.path === '/' ? `/${node.objectId}` : `${parentNode.path}/${node.objectId}`;
    }

    private calculateNewIndex(parentNode: TreeNode, targetNode: TreeNode, position: 'before' | 'inside'): number {
        const childIds = this.childrenMap().get(parentNode.objectId) || [];
        const childNodes = childIds.map(id => this.nodeMap().get(id)!)
            .sort((a, b) => a.position - b.position);

        if (position === 'inside') {
            return childNodes.length; // Place at the end when dropping inside
        } else { // 'before'
            // Find target node's index in sorted children
            const targetIndex = childNodes.findIndex(node => node.objectId === targetNode.objectId);
            return targetIndex !== -1 ? targetIndex : childNodes.length;
        }
    }

    private recalculateNodePosition({ nodeId, newIndex }: { nodeId: string, newIndex: number }): void {
        const node = this.nodeMap().get(nodeId);
        if (!node) {
            console.warn(`Node with id ${nodeId} not found`);
            return;
        }

        const parentId = node.parentId || this.ROOT_ID;
        // Get children sorted by their current position
        const childNodes = this.getChildNodes(parentId).sort((a, b) => a.position - b.position);
        const childIds = childNodes.map(node => node.objectId);

        // Remove the node from its current position
        const currentIndex = childIds.indexOf(nodeId);
        if (currentIndex !== -1) {
            childIds.splice(currentIndex, 1);
        }

        // Insert the node at its new position
        childIds.splice(newIndex, 0, nodeId);

        // Update the positions of all children in the same parent
        this.updateChildPositions(parentId, childIds);
    }


    private updateChildPositions(parentId: string, childIds: string[]): void {
        const updates: Array<[string, Partial<TreeNode>]> = [];

        // Helper function to get full path for a node
        const getFullPath = (nodeId: string): string => {
            const node = this.nodeMap().get(nodeId);
            if (!node) return nodeId;

            const paths: string[] = [nodeId];
            let currentNode = node;

            while (currentNode.parentId) {
                paths.unshift(currentNode.parentId);
                currentNode = this.nodeMap().get(currentNode.parentId) || currentNode;
            }

            return '/' + paths.join('/');
        };

        // Create updates with correct positions and preserved full paths
        childIds.forEach((childId, index) => {
            const fullPath = getFullPath(childId);
            updates.push([childId, {
                position: index,
                path: fullPath
            }]);
        });

        // Apply all updates atomically
        this.nodeMap.update(currentMap => {
            const newMap = new Map(currentMap);
            updates.forEach(([childId, update]) => {
                const existingNode = newMap.get(childId);
                if (existingNode) {
                    newMap.set(childId, { ...existingNode, ...update });
                }
            });
            return newMap;
        });

        // Update children map with the new order
        this.childrenMap.update(currentMap => {
            const newMap = new Map(currentMap);
            newMap.set(parentId, childIds);
            return newMap;
        });

        // Log the updates
        this.logs.update(logs => [
            ...logs,
            ...updates.map(([childId]) => ({
                node: this.nodeMap().get(childId)!,
                action: TreeNodeAction.UPDATE,
                timestamp: Date.now()
            }))
        ]);
    }

    private updateChildrenMap(nodeMap: Map<string, TreeNode>): Map<string | null, string[]> {
        const newChildrenMap = new Map<string | null, string[]>();

        nodeMap.forEach(node => {
            if (node.objectId === this.ROOT_ID) return;

            const parentId = node.parentId;
            if (!newChildrenMap.has(parentId)) {
                newChildrenMap.set(parentId, []);
            }
            newChildrenMap.get(parentId)!.push(node.objectId);
        });

        return newChildrenMap;
    }


    private updateNodeInMap(objectId: string, updates: Partial<TreeNode>) {
        this.nodeMap.update(currentMap => {
            const newMap = new Map(currentMap);
            const existingNode = newMap.get(objectId);
            if (existingNode) {
                const updatedNode = { ...existingNode, ...updates };
                newMap.set(objectId, updatedNode);
            } else {
                console.warn(`Node ${objectId} not found in the node map`);
            }
            return newMap;
        });
    }


    dataToTreeNodes(projectId: string, storageObjects: AppStorageObject[]): TreeNode[] {
    const sameProject = this.rootNode().projectId === projectId;
    const rootNode = this.createRootNode(projectId, this.ROOT_ID);
    
    // Only reset active node and root node if project changes
    if (!sameProject) {
        this.activeNode.set(rootNode);
        this.rootNode.set(rootNode);
    }

    // Always preserve state when creating node map
    const newNodeMap = this.createNodeMap(rootNode, storageObjects, true);
    const newChildrenMap = this.updateChildrenMap(newNodeMap);
    
    this.nodeMap.set(newNodeMap);
    this.childrenMap.set(newChildrenMap);
    
    return Array.from(newNodeMap.values());
}

    private createNodeMap(rootNode: TreeNode, storageObjects: AppStorageObject[], reuseState = true): Map<string, TreeNode> {
    const newNodeMap = new Map<string, TreeNode>();
    
    // Set root node while preserving its state if exists
    const existingRoot = this.nodeMap().get(rootNode.objectId);
    newNodeMap.set(rootNode.objectId, reuseState && existingRoot ? 
        { ...existingRoot, ...rootNode } : 
        rootNode
    );

    // Process other nodes while preserving their states
    storageObjects.forEach(obj => {
        const existingNode = this.nodeMap().get(obj.objectId);
        newNodeMap.set(obj.objectId, reuseState && existingNode ? 
            { 
                ...existingNode,
                ...obj,
                open: existingNode.open // Explicitly preserve the open state
            } : 
            obj
        );
    });

    return newNodeMap;
}

    validateAndFixNodePathParentId(node: TreeNode): TreeNode {
        const pathParts = node.path.split('/').filter(Boolean); // Remove empty strings

        // Validate path structure and derive parentId
        if (pathParts.length === 0) {
            // Invalid path, return root-level node
            return {
                ...node,
                parentId: this.ROOT_ID,
                path: `/${node.objectId}`
            };
        }

        const lastId = pathParts[pathParts.length - 1];
        // If last part of path doesn't match objectId, fix it
        if (lastId !== node.objectId) {
            pathParts[pathParts.length - 1] = node.objectId;
        }

        // Derive parentId from path
        const parentId = pathParts.length === 1
            ? this.ROOT_ID
            : pathParts[pathParts.length - 2];

        return {
            ...node,
            parentId,
            path: `/${pathParts.join('/')}`
        };
    }


    createNode({ location, userId, projectId, targetNode, objectId, name }: {
        location: 'up' | 'down' | 'in',
        userId: string,
        projectId: string,
        targetNode: TreeNode,
        objectId: string,
        name: string
    }): TreeNode {
        objectId = objectId ?? nanoid();
        if (!userId || !projectId) {
            throw new Error('User ID or Space ID is missing');
        }

        const timestamp = new Date().toISOString();
        const childrenLength = this.getChildNodes(targetNode.objectId).length;
        let path = '';
        let position = 0;

        // Get target path parts
        const targetPathParts = targetNode.path.split('/').filter(Boolean);

        switch (location) {
            case 'up':
                // Same level as target, use target's parent path
                path = targetPathParts.length > 1
                    ? `/${targetPathParts.slice(0, -1).join('/')}/${objectId}`
                    : `/${objectId}`;
                position = targetNode.position;
                break;

            case 'down':
                // Same level as target, use target's parent path
                path = targetPathParts.length > 1
                    ? `/${targetPathParts.slice(0, -1).join('/')}/${objectId}`
                    : `/${objectId}`;
                position = targetNode.position + 1;
                break;

            case 'in':
                // Inside target, append to target's path
                path = targetNode.path === '/'
                    ? `/${objectId}`
                    : `${targetNode.path}/${objectId}`;
                position = childrenLength;
                break;
        }

        // Create the node with path-derived parentId
        const pathParts = path.split('/').filter(Boolean);
        const parentId = pathParts.length === 1
            ? this.ROOT_ID
            : pathParts[pathParts.length - 2];

        const nodeStorageObject = {
            parentId,
            objectId,
            projectId,
            name,
            path,
            position,
        };

        return nodeStorageObject;
    }



    getChildNodes(parentId: string | null): TreeNode[] {
        const childIds = this.childrenMap().get(parentId) || [];
        return childIds
            .map(id => this.nodeMap().get(id))
            .filter((node): node is TreeNode => !!node)
            .sort((a, b) => a.position - b.position);
    }


    // Method to get a specific node
    getNode(nodeId: string): TreeNode | undefined {
        return this.nodeMap().get(nodeId);
    }

    // Method to check if a node exists
    nodeExists(nodeId: string): boolean {
        return this.nodeMap().has(nodeId);
    }

}