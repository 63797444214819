import { BehaviorSubject } from 'rxjs';
import { PromptInputPayload, PromptPayload } from 'configs/global';

export interface TokenUsage {
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
}
export interface ChatMessage {
  messageId: string;
  senderUserId: string;
  content: string;
  reasoningContent?: string;
  role: string;
  messageCreatedAt?: string;
  usage?: {
    total_tokens: number;
    prompt_tokens: number;
    completion_tokens: number;
  };
}
export interface AIStreamResponse {
  usage: TokenUsage;
  content_snapshot: string;
  reasoning_snapshot: string;
  subscription: any;
}

export interface ProcessEvent {
  content_snapshot: string;
  reasoning_snapshot: string;
}
export interface StreamChatInput {
  input: string,
  messages: ChatMessage[],
  character_id?: string | null,
  onStart?: () => void,
  onProcess?: (processEvent: ProcessEvent) => void,
  onFinish?: () => void,
}

export interface LineType { isFirst: boolean, isReason: boolean };

export function newTokenUsage() {
  return { prompt_tokens: 0, completion_tokens: 0, total_tokens: 0 };
}

export async function fakeAiStreaming(
  input: string,
  settings: { abortController?: AbortController },
  cb: (processEvent: ProcessEvent) => void,
) {
  if (input.length === 0) {
    console.warn('input is empty!!', input);
  }
  input = input.length
    ? input
    : `\n
# **Giới thiệu**\n
this is a fake AI ~world~\n
## world\n
this is new data
`;

  let result: AIStreamResponse = {
    usage: {
      prompt_tokens: 0,
      completion_tokens: 0,
      total_tokens: 0,
    },
    content_snapshot: '',
    reasoning_snapshot: '',
    subscription: {},
  };

  const listChunk = ['Fake AI:\n'].concat(input.split(''));
  let processEvent = {
    content_snapshot: '',
    reasoning_snapshot: '',
    isFirst: false,
  }

  const tokenPerSecond = 30;
  const charactersPerToken = 4;
  const milisecondPerChar = 1000 / (tokenPerSecond * charactersPerToken); // use to be 100ms

  for (let i = 0; i < listChunk.length;) {
    await new Promise((resolve) => setTimeout(resolve, milisecondPerChar));
    const delta = listChunk[i];
    // console.log('delta', delta);
    processEvent.content_snapshot += delta;
    processEvent.reasoning_snapshot += delta;
    const choice = {
      delta: {
        content: '',
        reasoning_content: ''
      }
    }
    cb(processEvent);

    if (processEvent.isFirst === true) {
      processEvent.isFirst = false;
    }

    i++;

    // console.log('settings.abortController', settings.abortController)
    try {
      if (settings.abortController?.signal.aborted) {
        new DOMException('AbortError', 'The user aborted a request.');
        break;
      }
    } catch (error) {
      console.error('forgot abort?');
    }
  }

  result.content_snapshot = processEvent.content_snapshot;
  result.reasoning_snapshot = processEvent.reasoning_snapshot;
  return result;
}



export async function aiStreaming(
  input: string,
  settings: {
    subscription_id: string;
    model_id: string;
    character_id?: string | null;
    body?: Record<string, any>;
    api?: string;
    abortController?: AbortController;
    rankedOnlyResults?: any;
    temperature?: number;
    task?: 'chat' | 'completion';
    systemPrompt?: string;
    max_tokens?: number;
  },
  cb: (processEvent: ProcessEvent) => void,
) {
  const body = JSON.stringify({
    subscription_id: settings.subscription_id,
    model_id: settings.model_id,
    character_id: settings.character_id,
    input: input,
    context: settings.rankedOnlyResults,
    temperature: settings.temperature,
    task: settings.task ?? 'chat',
    systemPrompt: settings.systemPrompt ?? '',
    max_tokens: settings.max_tokens,
    ...(settings.body ?? {}),
  });
  // console.log('your body', body);

  let result: AIStreamResponse = {
    usage: {
      prompt_tokens: 0,
      completion_tokens: 0,
      total_tokens: 0,
    },
    content_snapshot: '',
    reasoning_snapshot: '',
    subscription: {},
  };
  await handleStream(settings, body, (lines_text: string, value: string) => {
    let processEvent = {
      content_snapshot: '', reasoning_snapshot: ''
    }
    // console.log('lines_text', lines_text);
    // data.toString() gives this -> data: {"id":"cmpl-7MwK...","object":"text_completion","created":1685702342,"choices":[{"text":" joy","index":0,"logprobs":null,"finish_reason":null}],"model":"text-davinci-003"}
    //     { "id": "2c216b10-cc65-42af-b9ba-b41f8fbf49af", "object": "chat.completion.chunk", "created": 1737722572, "model": "deepseek-reasoner", "system_fingerprint": "fp_1c5d8833bc", "choices": [{ "index": 0, "delta": { "content": null, "reasoning_content": " I" }, "logprobs": null, "finish_reason": null }], "usage": null }
    // // 
    // lines is an array of below lines
    const lines = lines_text.split('\n').filter((line) => line.trim() !== '');

    for (const line of lines) {
      let choice = { delta: { content: '', reasoning_content: '' } };
      try {
        const parsed = JSON.parse(line);
        if (parsed.type === 'content_block_delta' && parsed.delta.text) {
          choice = {
            delta: {
              content: parsed.delta.text,
              reasoning_content: '',
            }
          }
        } else if (parsed.choices[0]) {
          choice = parsed.choices[0];
        }

        if (choice?.delta.reasoning_content)
          processEvent.reasoning_snapshot += choice.delta.reasoning_content;

        if (choice?.delta.content)
          processEvent.content_snapshot += choice.delta.content;

        if (parsed.usage)
          result.usage = parsed.usage;
        if (parsed.subscription)
          result.subscription = parsed.subscription as { subscription_balance: number; subscription_amount: number; };

        // console.log('parsed', parsed, result.subscription, result.usage);
      } catch (error) {
        if (error instanceof SyntaxError) {
          // JSON parsing failed, likely due to incomplete JSON string.
          // We'll wait for the next lines to complete the JSON string.
          continue;
        } else {
          // If it's another type of error, log and rethrow it
          console.error('parse failed: ', error);
          console.error('parse line: ', `${line}`, choice);
          throw error;
        }
      }


      cb(processEvent);
    
    }

    result.content_snapshot = processEvent.content_snapshot;
    result.reasoning_snapshot = processEvent.reasoning_snapshot;

  });
  return result;
}

async function handleStream(
  { api, abortController }: { api?: string; abortController?: AbortController },
  body: string,
  callback: (result_text: string, value: string) => void,
) {
  abortController = abortController ?? new AbortController();
  api = api ?? '/v1/api/chat';
  const response = await fetch(api, {
    method: 'POST',
    body: body,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    },
    signal: abortController.signal,
  });
  // Check if the request was successful
  if (!response.ok) {
    // Throw an error if the server response was not ok
    // Check if the response is JSON or text
    const contentType = response.headers.get('content-type');
    let errorData;

    if (contentType && contentType.includes('application/json')) {
      errorData = await response.json();
      throw new Error(errorData.message, { cause: errorData });
    } else {
      errorData = await response.text();
      throw new Error(errorData, { cause: errorData });
    }

  }
  // Using reader encode
  // const reader = response.body!.getReader();
  // using decode stream
  const decoderTransformStream = new TextDecoderStream();
  const reader = response.body!.pipeThrough(decoderTransformStream).getReader();
  await textDecoderStream(reader, callback);
}

async function textDecoderStream(
  reader: ReadableStreamDefaultReader,
  callback: (result_text: string, value: string) => void,
) {
  let total = '';
  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    // Using reader encode
    // const decodedValue = decoder.decode(chunk);
    // using decode stream
    switch (value) {
      case 'ERROR:rate_limit_exceeded':
        total = 'Lỗi quá lượt truy cập. Rate Limit Exceeded';
        break;
      case 'ERROR:internal_server_error':
        total = 'Lỗi server. Internal Server Error';
        break;
      case 'ERROR:token_limit_reached':
        total = 'Lỗi token đạt giới hạn tối đa. Token Limit Reached';
        break;
      case 'ERROR:api_error':
        total = 'Lỗi API khi trả lời. API Error';
        break;
      case 'ERROR:unknown':
        total = 'Lỗi không xác định. unknown Error';
        break;
      default:
        total += value;
    }
    callback(total, value);
  }
}

export type AIStreamingStatus =
  | 'idle'
  | 'connecting'
  | 'streaming'
  | 'error'
  | 'result';

export interface AIServerPayload {
  promptPayload: PromptPayload;
  promptInputPayload: PromptInputPayload;
  enableFakeAI?: boolean | 'repeat';
  apiPayloadBody: { subscription_id: string, user_id: string, model_id: string };
}

export const streamToAIServer = async (
  state: {
    abortController?: AbortController;
    streamingSnapshot?: BehaviorSubject<string>;
    streamingStatus?: BehaviorSubject<AIStreamingStatus>;
  },
  aiServerPayload: AIServerPayload,
) => {
  const {
    abortController,
    streamingSnapshot,
    streamingStatus,
  } = state;
  const { promptPayload, promptInputPayload, enableFakeAI, apiPayloadBody } =
    aiServerPayload;
  const { prevText, selectionText, inputText } = promptInputPayload;

  let systemMessage = '';

  if (promptPayload.systemFormat) {
    systemMessage = fillingRegexToPayload(
      {
        pattern: promptPayload.systemFormat.pattern,
        userInputTextRule: promptPayload.systemFormat.userInputTextRule ?? '$userInputText',
        selectionTextRule:
          promptPayload.systemFormat.selectionTextRule ?? '$selectionText',
        previousTextRule:
          promptPayload.systemFormat.previousTextRule ?? '$previousText',
        subsequentTextRule:
          promptPayload.systemFormat.subsequentTextRule ?? '$subsequentText',
      },
      {
        prevText,
        selectionText,
        inputText,
      },
    );
  } else {
    systemMessage = promptPayload.systemPrompt ?? '';
  }

  let userMessage = '';
  if (promptPayload.userFormat) {
    userMessage = fillingRegexToPayload(
      {
        pattern: promptPayload.userFormat.pattern,
        userInputTextRule: promptPayload.userFormat.userInputTextRule ?? '$userInputText',
        selectionTextRule:
          promptPayload.userFormat.selectionTextRule ?? '$selectionText',
        previousTextRule:
          promptPayload.userFormat.previousTextRule ?? '$previousText',
        subsequentTextRule:
          promptPayload.userFormat.subsequentTextRule ?? '$subsequentText',
      },
      {
        prevText,
        selectionText,
        inputText,
      },
    );
  } else if (promptPayload.userPrompt) {
    userMessage = promptPayload.userPrompt;
  } else {
    if (
      promptPayload.systemFormat &&
      promptPayload.systemFormat.pattern.includes('$selectionText')
    ) {
      userMessage = '';
    } else {
      userMessage = selectionText;
    }
  }

  console.log('promptPayload', promptPayload);
  console.log('promptInputPayload', promptInputPayload);
  console.log('systemMessage', systemMessage);
  console.log('userMessage', userMessage);
  console.log(
    'enableFakeAI?',
    enableFakeAI,
    enableFakeAI === 'repeat' ? selectionText : '',
  );

  if (!systemMessage) {
    throw new Error('did you forgot system message?');
  }

  streamingStatus?.next('connecting');
  let streamAI!: AIStreamResponse;
  try {
    if (enableFakeAI) {
      streamAI = await fakeAiStreaming(
        enableFakeAI === 'repeat' ? selectionText : '',
        { abortController: abortController },
        (processEvent) => {
          streamingStatus?.next('streaming');
          streamingSnapshot?.next(processEvent.content_snapshot);
        },
      );
    } else {
      let content = '';
      streamAI = await aiStreaming(
        userMessage,
        {
          model_id: apiPayloadBody.model_id,
          subscription_id: apiPayloadBody.subscription_id,
          abortController,
          systemPrompt: systemMessage,
          task: 'chat',
          max_tokens: undefined
        },
        (processEvent) => {
          streamingStatus?.next('streaming');
          streamingSnapshot?.next(processEvent.content_snapshot);
        },
      );
    }

    console.log('streamAIAction result', streamAI);
    streamingStatus?.next('result');
    streamingSnapshot?.next(streamAI.content_snapshot);
    return streamAI;
  } catch (error) {
    console.error('catch an error:', error);
    streamingStatus?.next('error');
    throw new Error(error);
  }
};

export function fillingRegexToPayload(
  promptFormat: PromptPayload['userFormat'],
  promptInputPayload: PromptInputPayload,
) {
  let message = promptFormat?.pattern || '';
  const { prevText, selectionText, inputText } = promptInputPayload;

  if (promptFormat) {
    message = promptFormat.pattern;
    // EDITOR REGEX
    if (promptFormat.previousTextRule) {
      message = message.replaceAll(promptFormat.previousTextRule, prevText);
    }
    if (promptFormat.subsequentTextRule) {
      message = message.replaceAll(promptFormat.subsequentTextRule, '');
    }
    if (promptFormat.selectionTextRule) {
      message = message.replaceAll(
        promptFormat.selectionTextRule,
        selectionText,
      );
    }
    if (promptFormat.userInputTextRule) {
      message = message.replaceAll(promptFormat.userInputTextRule, inputText ?? '');
    }
  }
  return message;
}
