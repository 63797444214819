import { ClientError, GraphQLClient, RequestMiddleware, ResponseMiddleware, request } from 'graphql-request';
import { Subject } from 'rxjs';
import { ExchangeTokenDocument } from '@app/generated';
import { environment } from '../env/environment';

import { debugAction, requestBody, requestBody2, requestBody3 } from './debug';

// debugAction();

// Constants
const TOKEN_REFRESH_THRESHOLD = environment.production ? 10 * 60 * 1000 : 30 * 1000; // 10 min vs 30 seconds

const isBrowser = typeof window !== 'undefined';
const isProductionServer = process.env['NODE_ENV'] === 'production';
const ENDPOINT = '/v1/graphql';

// NOTE: error 400 if going 'http://graphql:80' because cross origin? => force to use server domain
// let GRAPHQL_BASE_URL = isBrowser ? window.location.origin : isProductionServer ? 'http://graphql:80' : 'http://127.0.0.1:3000';
let GRAPHQL_BASE_URL = isBrowser ? window.location.origin : isProductionServer ? 'https://losa.vn' : 'http://127.0.0.1:3000';
// GRAPHQL_BASE_URL = 'https://losa.vn';
// State management
let isExchangingToken = false;
export const graphqlErrors$ = new Subject<string>();

// Logging utility
const log = (message: string, data?: any) => {
  console.log(message, data);
};

const logError = (message: string, error: any) => {
  console.error(message, error);
};

// NOTE: server will be crash if
// https://github.com/angular/angular/issues/54393#issuecomment-1938580042

// Request Middleware
const requestMiddleware: RequestMiddleware = async (request) => {
  if (isBrowser) {
    // console.log("CLIENT: requestMiddleware request: ", { ...request });
    // Browser request handling
    if (!isExchangingToken) {
      await checkAccessTokenExpired();
    }
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      // NOTE: FAILED: because it overide previous header instead of adding new
      // request.headers = {
      //   ...request.headers, // Spread the existing headers
      //   'Authorization': `Bearer ${accessToken}`, // Add the new Authorization header
      // }
      // NOTE: graphql-request using old version: https://github.com/graffle-js/graffle/pull/1001/files
      // NOTE: modify request headers causing losing all like contentType application/json
      const headers = new Headers(request.headers);
      headers.set(`Authorization`, `Bearer ${accessToken}`)
      return {
        ...request,
        headers,
      }
    }
  }
  else {
    // if (request?.body?.toString().includes('getPublicProjects')) {
    //   console.log("SERVER: requestMiddleware request: ", request);
    //   console.log("SERVER: requestMiddleware compare CASE 1 match? ", (requestBody as any) === request.body, request.body, requestBody);
    //   console.log("SERVER: requestMiddleware compare CASE 2 match? ", requestBody2 === request.body, request.body, requestBody2);
    //   console.log("SERVER: requestMiddleware compare CASE 3 match? ", requestBody3 === request.body, request.body, requestBody3);
    // }
  }
  return request;
};
// Also modify the response middleware
const responseMiddleware: ResponseMiddleware = async (response, request) => {
  if (response instanceof Response) {
    const status = response.status;
    const contentType = response.headers.get('content-type');

    if (status !== 200) {
      // Log the HTTP error details
      console.error(`HTTP error! Status: ${status}, contentType: ${contentType}`);
      let responseBody = await response.text();
      console.error(`Response Body: ${responseBody}`);
      // Send the error to the graphqlErrors$ stream
      graphqlErrors$.next(`HTTP_ERROR: Status ${status}, Body: ${responseBody}`);
      return;
    }

    // If the status is 200, proceed to check for GraphQL errors
    const responseData = await response.json();
    if ('errors' in responseData) {
      const errors = responseData?.errors;
      if (errors?.length) {
        errors.forEach((error: any, index: number) => console.error(`\n+++GraphQL Error ITEM ${index}:`, error));
        const formattedErrors = errors.map((error: any) => ({
          message: error.message,
          path: error.path,
          locations: error.locations,
          extensions: error.extensions,
          code: error.extensions['code'] || 'UNKNOWN_ERROR',
          timestamp: new Date().toISOString(),
          originalError: error.originalError
        }));
        const errorMessage = formattedErrors
          .map((error: any) => `[${error.code}] ${error.message} ${error.path ? `at path: ${error.path.join('.')}` : ''}`)
          .join(', ');
        logError('GraphQL formattedErrors:', formattedErrors);
        graphqlErrors$.next(`GRAPHQL_ERROR: ${errorMessage}`);
      }
    }
    return;
  }

  if (response instanceof Error) {
    // Extract more details from the error
    graphqlErrors$.next(`NETWORK_ERROR: ${response.message}`);
    console.error('response instanceof Error:', response);
  }

};

// GraphQL Client instance
export const graphQLClient = new GraphQLClient(GRAPHQL_BASE_URL + ENDPOINT, {
  requestMiddleware,
  responseMiddleware,
});



// Token Management
export async function checkAccessTokenExpired(): Promise<boolean> {
  if (!isBrowser) return false;

  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  if (!accessToken || !refreshToken) return false;

  try {
    const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
    if (!decodedToken?.exp) return false;

    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();

    if (expirationTime - currentTime <= TOKEN_REFRESH_THRESHOLD) {
      return await exchangeToken();
    }

    return true;
  } catch (error) {
    logError('Token validation error:', error);
    return await exchangeToken();
  }
}

async function exchangeToken(): Promise<boolean> {
  if (!isBrowser) return false;
  if (isExchangingToken) {
    log('Token exchange already in progress');
    return false;
  }

  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    log('No refresh token available for exchange');
    return false;
  }

  try {
    isExchangingToken = true;
    const response = await request(GRAPHQL_BASE_URL + ENDPOINT, ExchangeTokenDocument, { refreshToken });

    if (response?.exchangeToken?.accessToken && response?.exchangeToken?.refreshToken) {
      localStorage.setItem('accessToken', response.exchangeToken.accessToken);
      localStorage.setItem('refreshToken', response.exchangeToken.refreshToken);
      localStorage.setItem('exchangeTokenAt', new Date().toISOString());
      return true;
    }

    log('Exchange response missing tokens');
    return false;
  } catch (error) {
    logError('Token exchange failed:', error);
    if (error instanceof ClientError) {
      logError('GraphQL error details:', {
        status: error.response?.status,
        errors: error.response?.errors
      });
    }
    return false;
  } finally {
    isExchangingToken = false;
  }
}