export interface AIModel {
    model_provider: string;
    model_id: string;
    model_type: 'openai' | 'anthorpic' | 'gemini' | 'chatgpt';

    model_rank: number;

    model_display_name: string;
    model_description: string;
    model_image: string | null;
    model_price: number;
}

// {
//    "model_provider": "hyperbolic",
//    "model_id": "hyperbolic_Qwen/QwQ-32B-Preview",
//     model_type: "gpt",

//     model_display_name: "QwQ-32B-Preview",

//     model_rank: 1,
//     model_description: "Qwen QwQ vượt trội với khả năng tư duy",
//     model_image: '/frontend-assets/images/model-family/gemini.jpeg',
//    
//     model_price: 0.0156,
// },


export const openAIModels: AIModel[] = [
    {
        model_id: "openai/gpt-4o-mini",
        model_rank: 1,
        model_provider: 'openai',
        model_type: 'openai',
        model_image: '/frontend-assets/images/model-family/openAI.jpeg',

        model_display_name: "GPT-4o mini",
        model_description: "OpenAI, tác vụ dễ, nhanh và rẻ",
        model_price: 0.0156,
    },
    {
        model_id: "openai/gpt-4o",
        model_rank: 2,
        model_provider: 'openai',
        model_type: 'openai',
        model_image: '/frontend-assets/images/model-family/openAI.jpeg',

        model_display_name: "GPT-4o",
        model_description: "OpenAI, công việc phức tạp, logic tính toán",
        model_price: 0.4680,
    },


    // RANK 3


];

export const anthorpicAIModels: AIModel[] = [
    {
        model_id: "anthorpic/claude-3-5-haiku",
        model_rank: 1,
        model_provider: "anthorpic",
        model_type: "anthorpic",
        model_image: '/frontend-assets/images/model-family/claude.jpeg',

        model_display_name: "Claude-haiku 3.5",
        model_description: "Anthorpic, tác vụ dễ, viết sáng tạo tốt ",
        model_price: 0.0156,
    },
    {
        model_id: "anthorpic/claude-3-5-sonnet",
        model_rank: 2,
        model_provider: "anthorpic",
        model_type: "anthorpic",
        model_image: '/frontend-assets/images/model-family/claude-level-3.jpeg',

        model_display_name: "Claude-Sonet-3.5",
        model_description: "Anthorpic, công việc phức tạp, logic tính toán, lập trình",
        model_price: 0.7020,
    },
];

export const googleAIModels: AIModel[] = [
    {
        model_id: "google/gemini-1.5-flash",
        model_rank: 1,
        model_provider: "google",
        model_type: "gemini",
        model_image: '/frontend-assets/images/model-family/gemini.jpeg',

        model_display_name: "Gemini-1.5 flash",
        model_description: "Google, tác vụ dễ, tốt cho dịch thuật",
        model_price: 0.0156,
    },
    {
        model_id: "google/gemini-1.5-pro",
        model_rank: 2,
        model_provider: "google",
        model_type: "gemini",
        model_image: '/frontend-assets/images/model-family/gemini.jpeg',

        model_display_name: "Gemini-1.5 pro",
        model_description: "Google, công việc phức tạp, logic tính toán, dịch thuật",
        model_price: 0.39,
    },
    {
        model_id: "google/gemini-1.5-pro",
        model_rank: 2,
        model_provider: "google",
        model_type: "gemini",
        model_image: '/frontend-assets/images/model-family/gemini.jpeg',

        model_display_name: "Gemini-1.5 pro",
        model_description: "Google, công việc phức tạp, logic tính toán, dịch thuật",
        model_price: 0.39,
    },
    {
        model_id: "google/gemini-2.0-flash-thinking-exp",
        model_rank: 3,
        model_provider: "google",
        model_type: "gemini",
        model_image: '/frontend-assets/images/model-family/gemini.jpeg',

        model_display_name: "Gemini-2.0 flash-thinking-exp",
        model_description: "Google, công việc phức tạp, logic tính toán, dịch thuật",
        model_price: 0.0156,
    },
];

export const deepseekAIModels: AIModel[] = [
    {
        model_id: "deepseek/deepseek-chat",
        model_rank: 2,
        model_provider: "deepseek",
        model_type: "chatgpt",
        model_image: '/frontend-assets/images/model-family/deepseek.png',

        model_display_name: "Deepseek 3",
        model_description: "Deepseek, công việc phức tạp, logic tính toán",
        model_price: 0.0156,
    },
    {
        model_id: "deepseek/deepseek-reasoner",
        model_rank: 3,
        model_provider: "deepseek",
        model_type: "chatgpt",
        model_image: '/frontend-assets/images/model-family/deepseek.png',

        model_display_name: "Deepseek Reasoner",
        model_description: "Deepseek mô hình suy luận mới",
        model_price: 0.02,
    },
]

export const alibabaAIModels: AIModel[] = [
    {
        model_id: "alibaba/qwen-turbo",
        model_rank: 1,
        model_provider: "alibaba",
        model_type: 'chatgpt',
        model_image: '/frontend-assets/images/model-family/qwen.jpeg',

        model_display_name: "Qwen Turbo",
        model_description: "Alibaba, tác vụ dễ, tốc độ cao",
        model_price: 0.0156,
    },
    {
        model_id: "alibaba/qwen-plus",
        model_rank: 2,
        model_provider: "alibaba",
        model_type: 'chatgpt',
        model_image: '/frontend-assets/images/model-family/qwen.jpeg',

        model_display_name: "Qwen plus",
        model_description: "Alibaba, công việc phức tạp, logic tính toán",
        model_price: 0.02,
    },
]

export const volcengineAIModels: AIModel[] = [
    {
        model_id: "volcengine/ep-20250124125757-dztrr",
        model_rank: 1,
        model_provider: "volcengine",
        model_type: 'chatgpt',
        model_image: '/frontend-assets/images/model-family/doubao.png',

        model_display_name: "Doubao-lite 1.5",
        model_description: "Volcengine, tác vụ dễ, tốc độ cao",
        model_price: 0.0156,
    },
    {
        model_id: "volcengine/ep-20250124125728-65j9m",
        model_rank: 2,
        model_provider: "volcengine",
        model_type: 'chatgpt',
        model_image: '/frontend-assets/images/model-family/doubao.png',

        model_display_name: "Doubao-pro 1.5",
        model_description: "Volcengine, công việc phức tạp, logic tính toán",
        model_price: 0.02,
    },
]

export const minimaxModels: AIModel[] = [
   
]

export const modelList: AIModel[] = [
    ...openAIModels,
    ...anthorpicAIModels,
    ...googleAIModels,
    ...deepseekAIModels,
    ...alibabaAIModels,
    ...volcengineAIModels,
];


/**
 Magic Rank Title
 https://mushokutensei.fandom.com/wiki/Magic#Magic_Rank_Title
Rank	Title	Scale
1	Beginner / Elementary	Basic mana manipulation.
2	Intermediate	Advanced mana manipulation.
3	Advanced	Complex mana manipulation.Generally large area of effect.
4	Saintly / Holy	Large scale complex mana manipulation.Usually spells that affects the weather.
5	Kingly	Builds upon Saint - class Magic by increasing its effectiveness.
6	Imperial / Emperor	Further enhances the scale of King - class Magic
7	Divine / God	Continental scale magic.
 */


