import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { toggleElementOnClick, clickInsideBlur } from '@app/frontend-core';
import { LandingFooterComponent } from './components/landing-footer/landing-footer.component';
import { ChevronDown, LucideAngularModule } from 'lucide-angular';
import { SideDrawerComponent } from 'frontend-ui/src/layout/side-drawer.component';

interface PathLinkChild {
  title: string;
  path?: string;
  fragment?: string;
  href?: string;
  class?: string;
}
interface PathLink extends PathLinkChild {
  children?: PathLinkChild[];
}

@Component({
    selector: 'buyer-layout',
    imports: [RouterOutlet, RouterLink, CommonModule, LandingFooterComponent, LucideAngularModule, SideDrawerComponent],
    template: `
<app-side-drawer>
    <ng-container [ngTemplateOutlet]="mainTemplate"></ng-container>
</app-side-drawer>

<ng-template #mainTemplate>
      <div class="navbar bg-base-100 text-base-content sticky top-0 flex justify-between md:justify-center bg-opacity-90 backdrop-blur transition-shadow duration-100 [transform:translate3d(0,0,0)] w-full min-h-6 h-6 md:min-h-10 md:h-10 z-10">
        <!-- <div class="dropdown">
          <div tabindex="0" role="button" class="btn btn-xs md:btn-sm btn-ghost lg:hidden" (click)="toggleElementOnClick($event)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabindex="0"
            class="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
          >
            @for (item of navigationLinks; track $index) {
              @if (item.children) {
                <li>
                  @if(item.href) {
                  <a target="_blank" [href]="item.href" (click)="clickInsideBlur()"> {{ item.title }} </a>
                  }@else {
                    <a [ngClass]="item.class" [routerLink]="item.path" (click)="clickInsideBlur()"> {{ item.title }} </a>
                  }
                  <ul class="p-2">
                    @for (child of item.children; track $index) {
                      <li>
                        @if(child.href) {
                        <a target="_blank" [href]="child.href" (click)="clickInsideBlur()">{{ child.title }}</a>
                        }@else {
                          <a class="h-full" [routerLink]="child.path" [fragment]="child.fragment" (click)="clickInsideBlur()" [href]="item.href">{{ child.title }}</a>
                        }
                      </li>
                    }
                  </ul>
                </li>
              } @else {
                <li>
                   @if(item.href) {
                  <a target="_blank" [href]="item.href" (click)="clickInsideBlur()"> {{ item.title }} </a>
                  }@else {
                    <a [ngClass]="item.class" [routerLink]="item.path" (click)="clickInsideBlur()"> {{ item.title }} </a>
                  }
                </li>
              }
            }
          </ul>
        </div> -->


      <div class="navbar-start">
        <!-- <a routerLink="/" class="btn btn-xs md:btn-sm btn-ghost flex justify-center items-center">
          <img class="w-6 h-6 md:w-9 md:h-9" src="/frontend-assets/icon.webp"/>
        </a> -->
      </div>
      <div class="navbar-center flex">
        <ul class="menu menu-horizontal px-1">
          <!-- FOR DESKTOP -->
          @for (item of navigationLinks; track $index) {
            @if (item.children) {
              <li class="dropdown !p-0">
                  <div
                    tabindex="0"
                    role="button"
                    class="px-4 py-2 flex"
                    (click)="toggleElementOnClick($event)"
                  >
                     {{ item.title }}
                    <lucide-icon class="mx-2 self-center h-full" [name]="icons.ChevronDown"></lucide-icon>
                  </div>
                  <ul
                    tabindex="0"
                    class="dropdown-content bg-base-100 rounded-box z-[1] w-52 p-2 shadow flex flex-col"
                  >
                    @for (child of item.children; track $index) {
                      @if(child.href) {
                      <li><a target="_blank" [href]="child.href" (click)="clickInsideBlur()">{{ child.title }}</a>
                      }@else {
                        <li><a class="h-full flex" [routerLink]="child.path" [fragment]="child.fragment" (click)="clickInsideBlur()">{{ child.title }}</a></li>
                      }
                    }
                  </ul>
              </li>
            } @else {
              <li>
                @if(item.href) {
                  <a target="_blank" [href]="item.href" (click)="clickInsideBlur()"> {{ item.title }} </a>
                  }@else {
                    <a class="h-full flex" [routerLink]="item.path" (click)="clickInsideBlur()"> {{ item.title }} </a>
                  }
              </li>
            }
          }
        </ul>
      </div>
     <div class="navbar-end">
       <!-- <a class="h-full btn btn-ghost btn-sm md:btn-base text-secondary" [routerLink]="'/chat/losa'">Bắt đầu</a> -->
     </div>
    </div>
    <div class="mx-auto pb-12 h-full">
      <router-outlet></router-outlet>
    </div>
    <app-landing-footer class="w-full"></app-landing-footer>
</ng-template>

  `,
    styles: [
        `
      :host {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class buyerWebSharedLayout {
  toggleElementOnClick = toggleElementOnClick;
  clickInsideBlur = clickInsideBlur;

  icons = { ChevronDown };
  navigationLinks: PathLink[] = [
    { path: '/blog', title: 'Blog' },
    { path: '/pricing', title: 'Giá tiền' },

    {
      path: '',
      title: 'Tài nguyên',
      children: [

        { href: 'https://www.facebook.com/losaai/', title: 'Fanpage Facebook' },
        { path: '/roadmap', title: 'Lộ trình phát triển' },
        { path: '/changelog', title: 'Nhật ký thay đổi' },
      ],
    },
  ];
  constructor() { }
}
