import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef } from '@ngneat/dialog';
import { frontendDialogTemplate } from '../frontend-dialog.template/frontend-dialog.template.js';

@Component({
    selector: 'dialog-question-answer',
    imports: [CommonModule, frontendDialogTemplate],
    templateUrl: './question-answer.dialog.html',
    styles: [
        `
      :host {
        display: block;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionAnswerDialog {
  dialogRef = inject(DialogRef, { optional: true });
  data = this.dialogRef?.data;
}
