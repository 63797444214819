import { APP_ID, enableProdMode, EnvironmentProviders, inject, InjectionToken, makeStateKey, PLATFORM_ID, provideEnvironmentInitializer, provideExperimentalZonelessChangeDetection, Provider, TransferState } from '@angular/core';
import { PeopleRouting, buyerSharedRouting } from '@app/frontend-ui';
import { environment} from '@app/frontend-core';
import { RouteReuseStrategy, TitleStrategy } from '@angular/router';
import { provideDialogConfig } from '@ngneat/dialog';
import { QueryClient, dehydrate, hydrate, provideTanStackQuery, withDevtools } from '@tanstack/angular-query-experimental'
import { AppTitleStrategy } from 'frontend-core/src/settings/app-title.strategy';
import { CustomRouteReuseStrategy } from 'frontend-core/src/settings/custom-route-reuse.strategy';

import { provideHttpClient, withFetch } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Routes, provideRouter, withRouterConfig, withInMemoryScrolling } from '@angular/router';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

if (environment.production) {
  enableProdMode();
}



const chatterWebRouting: Routes = [
  {
    path: 'editor',
    loadComponent: () =>
      import('../../../../frontend-ui/src/chatter-dashboard/pages/editor/editor.page').then(m => m.EditorPage),
    data: {
      reuseComponent: true,
    },
  },
  {
    path: 'character',
    loadComponent: () =>
      import('../../../../frontend-ui/src/chatter-dashboard/pages/character-store/character-store.page').then((m) => m.CharacterStorePage),
    data: {
      reuseComponent: true,
    },
  },
  {
    path: 'chat/:characterId',
    loadComponent: () =>
      import('../../../../frontend-ui/src/chatter-dashboard/pages/chat-conversation/chat-conversation.page').then((m) => m.ChatConversationPage),
    data: {
      reuseComponent: true,
    },
  },
  {
    path: 'learn',
    loadComponent: () =>
      import('frontend-ui/src/chatter-dashboard/pages/projects/project-home.page').then(
        ({ ProjectHomePage }) => ProjectHomePage,
      ),
    data: {
      reuseComponent: true,
    },
  },
  {
    path: 'learn/:projectId/:objectId',
    loadComponent: () =>
      import('frontend-ui/src/chatter-dashboard/pages/projects/project-read.page').then(
        ({ ProjectReadPage }) => ProjectReadPage,
      ),
    data: {
      reuseComponent: true,
    },
  },
  {
    path: 'prompt',
    loadComponent: () =>
      import('frontend-ui/src/chatter-dashboard/pages/prompt/prompt.page').then(
        ({ PromptPage }) => PromptPage,
      ),
    data: {
      reuseComponent: true,
    },
  },
  {
    path: '',
    redirectTo: '/chat/',
    pathMatch: 'full',
  },
];

export const appRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('frontend-ui/src/layout/main/main.layout').then(
        (c) => c.MainLayout,
      ),
    children: [
      ...chatterWebRouting,
      ...buyerSharedRouting,
      {
        path: 'profile',
        loadComponent: () =>
          import('frontend-ui/src/chatter-dashboard/pages/profile/profile.page').then(
            ({ ProfilePage }) => ProfilePage,
          ),
      },
    ]
  },
  ...PeopleRouting,

  {
    path: 'not-found',
    loadComponent: () =>
      import('frontend-ui/src/app-buyer/pages/not-found/not-found.page').then(
        ({ NotFoundPage }) => NotFoundPage,
      ),
  },
  { path: '**', pathMatch: 'full', redirectTo: 'not-found' },
];


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      staleTime: 60 * 1000,
    },
  },
});

export const GRAPHQL_CACHE_KEY = makeStateKey<Record<string, any>>('GRAPHQL_CACHE');

export const frontendProvider: (Provider | EnvironmentProviders)[] = [
  provideTanStackQuery(queryClient,
    withDevtools()
  ),
  // SSR  https://github.com/TanStack/query/discussions/6293#discussioncomment-9908189
  // https://github.com/TanStack/query/discussions/6293#discussioncomment-11676361
  provideEnvironmentInitializer(() => {
    const transferState = inject(TransferState);
    const platform_id = inject(PLATFORM_ID);
    // console.log('isBrowser?', isPlatformBrowser(platform_id));
    if (isPlatformBrowser(platform_id)) {
      // restore on client
      const state = transferState.get(GRAPHQL_CACHE_KEY, {});
      console.log('BROWSER: restoring checkpoint from Angular', state);
      hydrate(queryClient, state);
    } else {
      // server
      transferState.onSerialize(GRAPHQL_CACHE_KEY, () => {
        const state = dehydrate(queryClient) // to also include Errors
        // console.log('SERVER: creating checkpoint to Angular once render finished.', state);
        return state;
      })
    }
  }), 
  provideDialogConfig({}),
  { provide: TitleStrategy, useClass: AppTitleStrategy },
  { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
  // provideZoneChangeDetection({ eventCoalescing: true })
];



export const providers: (Provider | EnvironmentProviders)[] = [
  ...frontendProvider,
  provideRouter(appRoutes, withInMemoryScrolling({
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    // https://github.com/atscott/angular/blob/ecad53c616ee3e300a4aa6290e7c4493ede8a615/adev/src/app/app-scroller.ts
  })),
  { provide: APP_ID, useValue: 'serverApp' },
  provideExperimentalZonelessChangeDetection(),
  provideHttpClient(withFetch()),
  // SSR only work with httpClient
  provideClientHydration(withHttpTransferCacheOptions({
    includePostRequests: true
  })),
  { provide: ErrorHandler, useClass: ErrorHandler },
  // this one could lead to cache ???
  // ServiceWorkerModule.register('ngsw-worker.js', {
  //     enabled: environment.production,
  //     registrationStrategy: 'registerImmediately',
  // }),
];
