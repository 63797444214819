import { ApplicationRef, ChangeDetectorRef, Injectable, afterNextRender, createComponent, inject } from '@angular/core';
import { ConversationGear, PeopleGear, SubscriptionGear } from '@app/frontend-state';
import { isBrowser, isMobile, PlausibleService } from '../services';
import { DOCUMENT } from '@angular/common';
import { ThemeService } from '../services/theme.service';
import { fromEvent, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChatterStarter {
  document = inject(DOCUMENT);
  themeService = inject(ThemeService);
  subscriptionGear = inject(SubscriptionGear);
  peopleGear = inject(PeopleGear);
  conversationGear = inject(ConversationGear);
  plausibleService = inject(PlausibleService);
  applicationRef = inject(ApplicationRef);

  constructor() {
    if (isBrowser()) {
      this.plausibleService.start();
      fromEvent(document, 'mousemove').pipe(take(1)).subscribe(() => {
        if (!isMobile()) {
          this.themeService.themeStart();
          this.devtoolDebug();
        }
      });
    }
  }


  async onUser() {
    await this.subscriptionGear.mySubscriptionQuery.refetch();
    const currentSubscription = this.subscriptionGear.mySubscription();
    if (currentSubscription) {
      this.conversationGear.checkRemainStatus({
        subscription_balance: currentSubscription.subscriptionBalance!,
        subscriptionDailyValue: currentSubscription.subscriptionDailyValue!,
      });
    }
  }


  async devtoolDebug() {
    const search = this.document.location.search;
    // console.log('search', search);
    if (search.includes('debug') || search.includes('DEBUG')) {
      console.log('eruda init', search);
      const eruda = await import('eruda');
      eruda.default.init();
      console.log('url', search, this.document.location);

    }
  }


  // quotes = [
  //   {
  //     text: 'Trí tưởng tượng quan trọng hơn kiến thức. Kiến thức là hữu hạn. Trí tưởng tượng là vô cùng và bao quát toàn thế giới',
  //     author: 'Albert Einstein',
  //   },
  //   {
  //     text: 'Hãy là thay đổi mà bạn muốn thấy trong thế giới',
  //     author: 'Mahatma Gandhi',
  //   },
  //   {
  //     text: 'Hành trình ngàn dặm bắt đầu từ một bước chân',
  //     author: 'Lão Tử',
  //   },
  // ];

  // async startAnime() {
  //   const currentDate = new Date().toLocaleDateString();
  //   const localDate = localStorage.getItem('startup_date');
  //   if (environment.production && localDate !== currentDate) {
  //     const randomQuote = this.quotes[Math.floor(Math.random() * this.quotes.length)];
  //     await this.animate(randomQuote);
  //     localStorage.setItem('startup_date', currentDate);
  //   }
  // }

  //   async animate(quote: { text: string; author: string }) {
  //     const template = document.createElement('template');
  //     template.innerHTML = `
  // <style>
  // .corner-borders-only {
  //   --size: 32px;
  //   width: 90vw;
  //   height: 40vh;
  //   padding: 10px;
  //   background:
  //     linear-gradient(blue var(--size), transparent 0 calc(100% - var(--size)), blue 0) 0 0 / 2px 100%,
  //     linear-gradient(blue var(--size), transparent 0 calc(100% - var(--size)), blue 0) 100% 0 / 2px 100%,
  //     linear-gradient(to right, blue var(--size), transparent 0 calc(100% - var(--size)), blue 0) 0 0 / 100% 2px,
  //     linear-gradient(to right, blue var(--size), transparent 0 calc(100% - var(--size)), blue 0) 0 100% / 100% 2px
  //     ;
  //   background-repeat: no-repeat;
  // }
  // </style>
  // <!-- https://synduality-ada.bn-ent.net/ -->
  // <!-- https://github.com/new-data-services/tailwindcss-animated -->

  // <div id="loader-wrap" class="fixed top-0 left-0 w-full h-full bg-gray-900 z-50 flex items-center justify-center">
  //     <div id="loader" class="bg-white text-accent rounded-full shadow-md p-4">
  //         <div id="loader-info" class="flex flex-col items-center justify-center space-y-2 corner-borders-only animate-jump-in">
  //             <p class="relative text-xs md:text-xl lg:text-xxl font-bold whitespace-pre-line py-2 my-6">
  //                 ${quote.text}
  //                 <span class="absolute left-0 md:left-[initial] md:right-0 -bottom-4 md:-bottom-8 w-max point text-sm"> - ${quote.author} - </span>
  //             </p>
  //             <p id="percent" class="text-base font-bold ">0%</p>
  //             <div class="flex items-center space-x-2">
  //                 <progress id="process" class="progress progress-secondary w-[80vw] transition-all duration-600 ease-linear" value="0" max="100"></progress>
  //             </div>
  //             <p class="text-sm font-medium ">Losa.vn thư ký AI trực tuyến</p>
  //             <p class="text-sm ">Losa</p>
  //         </div>
  //     </div>
  //     <p id="after-load" class="text-white text-center text-2xl mt-4 hidden animate-fade-down">Tôi ở bên bạn</p>
  // </div>
  //     `;

  //     const content = template.content.cloneNode(true);
  //     document.body.appendChild(content);

  //     const loaderWrap = document.getElementById('loader-wrap');
  //     const loader = document.getElementById('loader');
  //     const loaderInfo = document.getElementById('loader-info');
  //     const percent = document.getElementById('percent');
  //     const process = document.getElementById('process');
  //     const afterLoad = document.getElementById('after-load');

  //     if (loaderWrap && loader && loaderInfo && afterLoad && process && percent) {
  //       const processbarAnimation = (durationSecond = 1) =>
  //         new Promise((resolve) => {
  //           let startTime: number | null = null;

  //           const animate = (timestamp: number) => {
  //             if (!startTime) startTime = timestamp;
  //             const elapsed = (timestamp - startTime) / 1000; // Convert to seconds
  //             const progress = Math.min(elapsed / durationSecond, 1); // Ensure progress does not exceed 1

  //             process?.setAttribute('value', (progress * 100).toFixed(2));
  //             if (percent) { percent.innerHTML = (progress * 100).toFixed(0) + '%' }

  //             if (progress < 1) {
  //               requestAnimationFrame(animate);
  //             } else {
  //               resolve('done');
  //             }
  //           };

  //           requestAnimationFrame(animate);
  //         });

  //       const wait = (x = 1) =>
  //         new Promise((resolve, reject) => {
  //           setTimeout(() => {
  //             resolve('done');
  //           }, x * 1000);
  //         });

  //       const animateElement = (
  //         targetElement: HTMLElement,
  //         action: 'add' | 'remove',
  //         className: string,
  //       ) =>
  //         new Promise((resolve, reject) => {
  //           targetElement.classList.add(
  //             'transition-all',
  //             'duration-600',
  //             'ease-linear',
  //           );
  //           targetElement.classList[action](className);
  //           targetElement.addEventListener('animationend', function handler() {
  //             // Remove the event listener to avoid memory leaks
  //             // console.log('targetElement end', targetElement)
  //             targetElement.removeEventListener('animationend', handler);
  //             // Resolve the promise when the animation ends
  //             resolve('done');
  //           });
  //         });

  //       await animateElement(loader, 'add', 'animate-fade-down');
  //       // await wait(0.7);
  //       await wait(0.35);
  //       // await processbarAnimation(3);
  //       await processbarAnimation(1.5);
  //       percent.innerHTML = 'sẵn sàng' + ' 100%';
  //       await animateElement(percent, 'add', 'animate-shake');
  //       // await wait(1);
  //       await wait(0.5);
  //       // await wait(100);

  //       await animateElement(loaderInfo, 'add', 'animate-jump-out');
  //       loader.classList.add('hidden');
  //       await animateElement(afterLoad, 'remove', 'hidden');
  //       await wait(0.3);
  //       await animateElement(afterLoad, 'add', 'animate-jump-out');
  //       afterLoad.classList.add('hidden');
  //       await wait(0.1);
  //       loaderWrap.classList.add('hidden');
  //     }
  //   }
}
