import { Injectable, inject, signal } from '@angular/core';
import { SnackbarService, graphQLClient } from '@app/frontend-core';
import { AppMessageConversation, GetConversationsDocument, GetConversationsQueryVariables, GetMessagesDocument, GetMessagesQueryVariables, NewConversationDocument, NewConversationMutationVariables, NewMessageDocument, NewMessageMutationVariables, NewThreadDocument, NewThreadMutationVariables, RemoveConversationDocument, RemoveConversationMutationVariables, UpdateConversationDocument } from '@app/generated';
import { injectMutation, injectQuery, injectQueryClient } from '@tanstack/angular-query-experimental';
import { PeopleGear, SubscriptionGear } from 'frontend-state/src/people';
import { ModelGear } from './model.gear';
import { CharacterGear } from './character.gear';
import { aiStreaming, ChatMessage } from 'frontend-ui/src/chatter-dashboard/pages/ai-client';


@Injectable({ providedIn: 'root' })
export class ConversationGear {
    peopleGear = inject(PeopleGear);
    snackbarService = inject(SnackbarService);
    activeConversationId = signal<string | null | undefined>(null);
    queryClient = injectQueryClient();
    currentConversationUserIds = signal<string[]>([]);
    subscriptionGear = inject(SubscriptionGear);
    homePageUserInput = signal<string>('');
    modelGear = inject(ModelGear);
    characterGear = inject(CharacterGear);

    async setNewTitleOfConversationInStream(chatlog = 'user: hello\n model: Hi, how I can help?') {
        const activeConversation = this.getActiveConversation();
        const old = this.currentConversationsGet() || [];
        const newData = [...old];
        if (activeConversation) {
            try {
                const streamingResult = await aiStreaming(chatlog, {
                    model_id: this.modelGear.aiModelInformation()!.model_id,
                    subscription_id: this.subscriptionGear.activeSubscriptionId(),
                    systemPrompt: `write a short title that summarizes the topic of the conversation in chatlog language. Be concise and respond with just the title which is written in the given Language.
`,
                    max_tokens: 30,

                    body: {},
                }, (processEvent) => {
                    newData[old.indexOf(activeConversation)] = {
                        ...activeConversation,
                        conversationTitle: processEvent.content_snapshot
                    };
                    this.currentConversationsUpdate(newData);
                });

                const { appMessageUpdateConversation } = await graphQLClient.request(UpdateConversationDocument, {
                    conversationId: activeConversation.conversationId,
                    conversationTitle: streamingResult.content_snapshot,
                    conversationUserIds: null,
                });
            } catch (error) {
                console.error(error);
                this.snackbarService.showError(error.message);
            }
        }
    }

    getConversations =injectQuery(() => ({
        queryKey: ['memberConversations', {
            pConversationUserIds: this.currentConversationUserIds()
        } as GetConversationsQueryVariables],
        queryFn: async (queryKey) => {
            const { appMessageGetConversations } = await graphQLClient.request(GetConversationsDocument, {
                pConversationUserIds: this.currentConversationUserIds(),
                pSearch: '',
            });
            return appMessageGetConversations?.nodes || [];
            // return [...(appMessageGetConversations?.nodes || []), ...(appMessageGetConversations?.nodes || [])] || [];
        },
        enabled: !!this.peopleGear.userId(),
    }));


    currentConversationsUpdate(newData: AppMessageConversation[]) {
        // Optimistically update to the new value
        this.queryClient.setQueryData(['memberConversations', {
            pConversationUserIds: this.currentConversationUserIds()
        } as GetConversationsQueryVariables], (old) => newData);
    }

    currentConversationsGet(): AppMessageConversation[] | undefined {
        return this.queryClient.getQueryData(['memberConversations', {
            pConversationUserIds: this.currentConversationUserIds()
        } as GetConversationsQueryVariables]);
    }

    getActiveConversation() {
        const appMessageConversations = this.currentConversationsGet();
        const activeConversation = appMessageConversations?.find((conversation) => conversation?.conversationId === this.activeConversationId())
        return activeConversation;
    }

    getActiveConversationIndex() {
        const appMessageConversations = this.currentConversationsGet();
        const activeIndex = appMessageConversations?.findIndex((conversation) => conversation?.conversationId === this.activeConversationId())
        return activeIndex;
    }

    queryGetMessages = injectQuery(() => ({
        queryKey: ['getMessages', {
            pConversationId: this.activeConversationId()!
        } as GetMessagesQueryVariables],
        queryFn: async (queryKey): Promise<ChatMessage[]> => {
            const pConversationId = (queryKey.queryKey[1] as GetMessagesQueryVariables).pConversationId;
            if (!pConversationId) return [];
            const { appMessageGetMessages } = await graphQLClient.request(GetMessagesDocument, {
                pConversationId,
                pSearch: '',
            });
            
            const messages = (appMessageGetMessages?.nodes ?? [])
                .filter(m => !!m)
                .map(chat => ({
                    senderUserId: chat.messageMetadata?.senderUserId!,
                    messageId: chat.message?.messageId!,
                    content: chat.message?.content!,
                    reasoningContent: chat.message?.reasoningContent!,
                    displayName: chat.sender?.displayName!,
                    messageCreatedAt: chat.message?.messageCreatedAt!,
                    role: this.peopleGear.userId() === chat.messageMetadata?.senderUserId! ? 'user' : 'assistant'
                }))
            console.log('queryGetMessages! imcoming changes', appMessageGetMessages?.nodes, messages);
            return messages as ChatMessage[];
        },
        enabled: !!this.activeConversationId()
    }));

    newMessageMutation = injectMutation(() => ({
        mutationFn: (newMessageData: NewMessageMutationVariables) => {
            return graphQLClient.request(NewMessageDocument, newMessageData);
        },
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);

        },
    }));

    modifyLocalMessages(messageData: ChatMessage) {
        this.queryClient.setQueryData(['getMessages', {
            pConversationId: this.activeConversationId()!
        } as GetMessagesQueryVariables], (old: ChatMessage[]) => {
            const messageIndex = old?.findIndex(msg => msg.messageId === messageData.messageId);
            if (messageIndex !== -1 || messageIndex === undefined) {
                const updatedMessages = [...old];
                updatedMessages[messageIndex] = messageData;
                return updatedMessages;
            } else {
                return [...old, messageData]
            }
        })
        return this.queryClient.getQueryData(['getMessages', {
            pConversationId: this.activeConversationId()!
        } as GetMessagesQueryVariables]) as ChatMessage[];
    }

    newConversationMutation = injectMutation(() => ({
        mutationFn: (v: NewConversationMutationVariables) => graphQLClient.request(NewConversationDocument, v),
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);
            return data.appMessageNewConversation;

        },
        onSettled: async () => {
            return await this.queryClient.invalidateQueries({ queryKey: ['memberConversations'] })
        },
    }));

    removeConversation = injectMutation(() => ({
        mutationFn: (v: RemoveConversationMutationVariables) => graphQLClient.request(RemoveConversationDocument, v),
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);
            this.snackbarService.showSuccess('Đã xoá');

        },
        onSettled: async () => {
            await this.queryClient.invalidateQueries({ queryKey: ['memberConversations'] })
            return await this.queryClient.invalidateQueries({ queryKey: ['messagesByConversationId'] })
        },
    }));


    newThreadMutation = injectMutation(() => ({
        mutationFn: (v: NewThreadMutationVariables) => graphQLClient.request(NewThreadDocument, v),
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);

        },
    }));


    remainStatus = signal({
        level10: false,
        level1: false,
        level0: false,
        currentPercent: 100,
    });

    checkRemainStatus(subscription: { subscriptionDailyValue: number; subscription_balance: number; }) {
        const remainPercent = subscription.subscription_balance / subscription.subscriptionDailyValue * 100;

        if (remainPercent < 0 && this.remainStatus().level0 === false) {
            this.snackbarService.showWarning('Dung lượng còn 0%');
            this.remainStatus.set({
                currentPercent: remainPercent,
                level10: true,
                level1: true,
                level0: true,
            });
        } else if (remainPercent < 10 && this.remainStatus().level10 === false) {
            this.snackbarService.showWarning('Dung lượng còn dưới 10%');
            this.remainStatus.set({
                currentPercent: remainPercent,
                level10: true,
                level1: false,
                level0: false,
            });
        } else if (remainPercent < 1 && this.remainStatus().level1 === false) {
            this.snackbarService.showWarning('Dung lượng còn dưới 1%');
            this.remainStatus.set({
                currentPercent: remainPercent,
                level10: true,
                level1: true,
                level0: false,
            });
        } else {
            this.remainStatus.set({
                currentPercent: remainPercent,
                level10: false,
                level1: false,
                level0: false,
            });
        }
    }

}